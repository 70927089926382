import * as React from "react";
import "./BonientService.scss";

export default class BonientService extends React.Component {
    public render() {
        return (
            <section className="bonient-service">
                <div className="bonient-service__wrap">
                    <h2 className="bonient-service__title">Что входит в абонентское обслуживание</h2>
                    <ul className="bonient-service__list">
                        <li className="bonient-service__item">
                            <h3 className="bonient-service__item-title">Бесплатный онлайн-сервис</h3>
                            <p className="bonient-service__item-text">Полностью бесплатный онлайн-сервис для спутникового слежения и контроля за автотранспортом. Никаких скрытых платежей</p>
                        </li>
                        <li className="bonient-service__item">
                            <h3 className="bonient-service__item-title">Обеспечение бесперебойной работы</h3>
                            <p className="bonient-service__item-text">Обеспечение бесперебойной работы веб-сервиса и оборудования, установленного на транспортное средство, обновление мобильных приложений</p>
                        </li>
                        <li className="bonient-service__item">
                            <h3 className="bonient-service__item-title">Техподдержка</h3>
                            <p className="bonient-service__item-text">Техподдержка, устранение неполадок, консультирование</p>
                        </li>
                    </ul>
                    {/* <a className="bonient-service__button button">Заказать установку системы</a> */}
                </div>
            </section>
        );
    }
}