import * as React from 'react';
import './HowToControlMonitoring.scss';

export default class HowToControlMonitoring extends React.Component {
    public render() {
        return (
            <section className="how-to-control-monitoring">
                <h2 className="how-to-control-monitoring__title">Как начать работать с системой</h2>
                <div className="how-to-control-monitoring__first-list-wrap">
                    <ul className="how-to-control-monitoring__first-list list">
                        <li className="how-to-control-monitoring__first-item">Спутниковые системы мониторинга ГЛОНАСС/GPS</li>
                        <li className="how-to-control-monitoring__first-item">Оборудование на автомобиль: трекеры, периферийные устройства  </li>
                        <li className="how-to-control-monitoring__first-item">Веб-сервис Транспорт.Онлайн</li>
                    </ul>
                    <div className="how-to-control-monitoring__first-img-wrap">
                        <img src="/img/control.png" className="how-to-control-monitoring__first-img" />
                    </div>
                </div>
                <ul className="how-to-control-monitoring__second-list">
                    <li className="how-to-control-monitoring__second-item">
                        <img className="how-to-control-monitoring__second-item-img" src="/img/how-to-control-monitoring/1.png" alt=""/>
                        <p className="how-to-control-monitoring__second-item-text">Слежение и контроль за автомобилем в режиме реального времени</p>
                    </li>
                    <li className="how-to-control-monitoring__second-item">
                        <img className="how-to-control-monitoring__second-item-img" src="/img/how-to-control-monitoring/2.png" alt=""/>
                        <p className="how-to-control-monitoring__second-item-text">Хранение на сервере истории от 1 недели до 3-х лет</p>
                    </li>
                    <li className="how-to-control-monitoring__second-item">
                        <img className="how-to-control-monitoring__second-item-img" src="/img/how-to-control-monitoring/3.png" alt=""/>
                        <p className="how-to-control-monitoring__second-item-text">Оповещения о важных событиях (нарушения, чрезвычайные ситуации)</p>
                    </li>
                    <li className="how-to-control-monitoring__second-item">
                        <img className="how-to-control-monitoring__second-item-img" src="/img/how-to-control-monitoring/4.png" alt=""/>
                        <p className="how-to-control-monitoring__second-item-text">Борьба с глушилками</p>
                    </li>
                </ul>
            </section>
        );
    }
}