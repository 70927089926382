import * as React from "react";
import './VistarMDocumentation.scss';

export default class VistarMDocumentation extends React.Component {
    public render() {
        return (
        <section className="vistar-m-documentation">
            <h2 className="vistar-m-documentation__title title">Документация</h2>
            <ul className="vistar-m-documentation__list list">
                <li className="vistar-m-documentation__item"><span><a href="/files/vistarm_func_1.5.pdf">Описание функциональных характеристик программного обеспечения</a></span></li>
                <li className="vistar-m-documentation__item"><span><a href="/files/install_1.5.pdf">Установка программного обеспечения</a></span></li>
                <li className="vistar-m-documentation__item"><span><a href="/files/quick_start_1.5.pdf">Быстрый старт</a></span></li>
                <li className="vistar-m-documentation__item"><span><a href="/files/user_guide_1.5.pdf">Руководство по эксплуатации системы (руководство пользователя)</a></span></li>
            </ul>
           {/* <p className="vistar-m-documentation__subtext"><b>*350 руб.</b> - более 10 единиц техники, <b>450 руб.</b> - до 10 машин</p> */}
        </section>
        );
    }
}